/* Admin Dashboard Styles */
.admin-dashboard-items {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 10rem;
}

.admin-dashboard-items h1, .admin-dashboard-items h2 {
  color: #ffffff;
  margin-bottom: 20px;
}

.error-message {
  color: #e74c3c;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #c0392b;
  border-radius: 4px;
  background-color: #2c3e50;
}

/* Filter Buttons */
.filter-buttons {
  margin-bottom: 20px;
}

.filter-button {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-button.active, .filter-button:hover {
  background-color: #1e90ff;
  color: #ffffff;
  border-color: #1c86ee;
}

/* Form Styles */
.rezani-form {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #2e2e2e;
  color: #e0e0e0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-input {
  margin-right: 10px;
}

.submit-button {
  background-color: #1e90ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.submit-button:hover {
  background-color: #1c86ee;
}

/* Rezani List Styles */
.rezani-list {
  list-style: none;
  padding: 0;
}

.rezani-item {
  background: #2e2e2e;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.rezani-item img {
  max-width: 100px;
  border-radius: 4px;
  margin-right: 15px;
}

.rezani-details {
  flex: 1;
}

.rezani-title {
  color: #ffffff;
  margin: 0;
}

.rezani-link {
  color: #e0e0e0;
}

.toggle-button {
  background-color: #1e90ff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.toggle-button:hover {
  background-color: #1c86ee;
}

/* Additional Styles for Filtered Lists */
.recommended-items {
  border-left: 5px solid #1e90ff;
}

.movie {
  border-left: 5px solid #ff4500;
}

.latest {
  border-left: 5px solid #32cd32;
}

/* Existing Styles */

/* New Styles */
.edit-button {
  background-color: #ffa500;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: #ff8c00;
}

.cancel-button {
  background-color: #dc143c;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #c8102e;
}

/* Filter Button Styles */
.filter-button.active {
  background-color: #1c86ee;
}


/* New Styles */
.edit-button {
  background-color: #ffa500;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: #ff8c00;
}

.cancel-button {
  background-color: #dc143c;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #c8102e;
}

/* Filter Button Styles */
.filter-button.active {
  background-color: #1c86ee;
}

/* Search Field Styles */
.search-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.filter-buttons {
  margin-bottom: 20px;
}

.filter-button {
  background-color: #1c86ee;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.filter-button.active {
  background-color: #155a8a;
}

.filter-button:hover {
  background-color: #1a5a9e;
}

.input-field,
.submit-button,
.cancel-button {
  margin-bottom: 10px;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #dc143c;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #c8102e;
}

.rezani-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.rezani-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}

.rezani-details {
  flex: 1;
}

.rezani-title {
  font-size: 18px;
  margin: 0;
}

.rezani-link {
  color: #1c86ee;
  text-decoration: none;
}

.edit-button {
  background-color: #ffa500;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: #ff8c00;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .rezani-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .rezani-item img {
    margin-bottom: 10px;
  }

  .input-field {
    padding: 8px;
  }

  .submit-button {
    padding: 8px 16px;
  }

  .filter-button {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

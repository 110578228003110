

/* Dashboard Container */
.admin-dashboard {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  background-color: #1e1e1e;
  border-radius: 8px;
  margin-top: 8rem;
}

/* Header */
.admin-dashboard h2,
.admin-dashboard h1,
.admin-dashboard h3 {
  color: #ffffff;
  margin-bottom: 20px;
}

/* Search Section */
.search-section-admin {
  margin-bottom: 20px;
}

.search-input-admin {
  width: 100%;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #333;
  color: #e0e0e0;
}

/* Form Section */
.form-section-admin {
  margin-bottom: 40px;
}

.form-container-admin {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
}

.form-container-admin input,
.form-container-admin textarea,
.button-admin {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #333;
  color: #e0e0e0;
}

.form-container-admin textarea {
  resize: vertical;
}

.form-container-admin button {
  background-color: #6200ea;
  border: none;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-container-admin button:hover {
  background-color: #00b377;
}

.button-danger {
  background-color: #b00020;
}

.button-danger:hover {
  background-color: #ff00dd;
}

/* Anime List */
.anime-list {
  list-style-type: none;
  padding: 0;
}

.anime-list li {
  padding: 10px;
  border-bottom: 1px solid #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2a2a;
  border-radius: 4px;
}

.anime-list li:last-child {
  border-bottom: none;
}

.anime-list span {
  color: #e0e0e0;
}

/* Edit Anime Section */
.edit-anime-section {
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-dashboard {
    padding: 10px;
  }
}

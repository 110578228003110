.cardshimmer {
  overflow: hidden;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #ffffff 8%, #494958 18%, #ffffff 33%);
  background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}
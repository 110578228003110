@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.scrollbar-style{
  scrollbar-width: thin;
  scrollbar-color: #000000 #ffffff00;
}


.scrollable-episodes-container {
  max-height: 150px; /* Adjust this value based on how many buttons you want to show */
  overflow-y: auto; /* Enables scrolling */
  padding-right: 10px; /* Adds some space for the scrollbar */
}

/* Add scrollbar styles to make it look nice */
.scrollable-episodes-container::-webkit-scrollbar {
  width: 2px;
}

.scrollable-episodes-container::-webkit-scrollbar-track {
  background: #2d2d2d; /* Dark background for scrollbar track */
}

.scrollable-episodes-container::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar color */
}

.scrollable-episodes-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar hover color */
}



@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




*{
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  border: none;
  outline: none;
}

html{
  background-color: var(--bg-color);
}

:root{
  --bg-color: #111827;
  --second-bg-color: #323946;
  --text-color: #fff;
  --main-color: #0ef;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}


@media screen and (max-width: 768px) {
  html{
    font-size: 85%;
  }
  
}